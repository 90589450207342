// Util function to get the access level from cookies, localStorage, or the Redux store.
// Then login the user in the background if the user has a valid access level but is not logged in.
// This is used to allow duplication of tabs and to keep the user logged in across tabs.
import { useState, useEffect } from "react";
import { getCookie } from "HelperFunctions/Cookies";
import useFetchAPI from "../Services/FetchApi";
import { useUserData } from "CustomHooks";

export const useAccessLevel = (reduxAccessLevel: number | null) => {
  const [accessLevel, setAccessLevel] = useState<number | null>(null);
  const [msg, setMsg] = useState<string>("");
  const [msgCode, setMsgCode] = useState<number>(0);
  const fetchApi = useFetchAPI();
  const { setUserData } = useUserData();

  useEffect(() => {
    const checkAccessLevel = async () => {
      // Check cookies and localStorage for access level
      const cookieAccessLevel = getCookie("access_level")
        ? parseInt(getCookie("access_level") as string, 10)
        : null;

      const localStorageAccessLevel = localStorage.getItem("access_level")
        ? parseInt(localStorage.getItem("access_level") as string, 10)
        : null;

      // Determine the prioritized access level
      const determinedAccessLevel = cookieAccessLevel ?? localStorageAccessLevel ?? reduxAccessLevel ?? null;

      setAccessLevel(determinedAccessLevel);

      // If Redux access level is null but cookies/localStorage has it, fetch user details from the API
      if (determinedAccessLevel && !reduxAccessLevel) {
        console.log("Logging in user in the background...");
        try {
          const response = await fetchApi("user/state_login");
          if (response.status && response.status >= 200 && response.status < 300) {
            setUserData(response.responseData);
            setMsgCode(0);
          } else {
            setMsg(response.msg || "There was a  problem logging in the user in the background. Please login again.");
            setMsgCode(1);
          }
        } catch (error: any) {
          setMsg("There was a problem logging in the user in the background. Please contact your administrator. " + error);
          setMsgCode(4);
        }
      }
    };

    checkAccessLevel();
  });

  return { accessLevel, msg, msgCode };
};
