import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // React Big Calendar styles
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Styles/rbcOverides.css'; // React overides for testing
import Bluey from '../Components/Bluey';
import useFetchApi from "../Services/FetchApi";
import {hideRefreshLogin} from "State/Reducers/refreshLoginSlice";

const localizer = momentLocalizer(moment);

const StyledCalendarWrapper = styled(Paper)(() => ({
    width: '80%',
    margin: '50px auto',
    // marginTop: '50px',
    // marginBottom: '50px',
    padding: '20px',
    minHeight: '90vh', // Ensures at least the full viewport height
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(239,239,239)', // Explicit solid background
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle shadow for depth
    borderRadius: '8px', // Optional: round the corners
}));

// Toolbar styles
const StyledToolbar = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
}));

// Calendar inline styles
const calendarStyles = {
    calendar: {
        fontFamily: 'Arial, sans-serif',
        // height: '100vh', // Allow room for multiple rows of events
        height: 'auto',
        flex: 1, // Ensures the calendar stretches to fill the wrapper
        backgroundColor: 'white',
    },
    event: {
        margin: '1px 2px',
        fontSize: '0.8rem',
        padding: '3px 3px',
        height: '20px',
        borderRadius: '4px',
        textAlign: 'center'
    },
    dateCell: {
        height: 'auto',
        verticalAlign: 'top',
        padding: '5px',
        border: '1px solid #ddd',
    },
    toolbarLabel: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#333',
        cursor: 'pointer', // Add pointer to indicate interactivity
        // textDecoration: 'underline', // Optional: Adds a visual cue
    },
};

const CheckboxLabel = styled('label')(() => ({
    display: 'flex',
    width: 200,
    alignItems: 'center',
    fontSize: '14px',
    cursor: 'pointer',
    gap: '10px',
}));


const CustomSearchBar = ({ searchValue, onSearchChange }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '500px',
                border: '2px solid #ccc',
                borderRadius: '5px',
                backgroundColor: '#f9f9f9',
                padding: '5px',
                marginLeft: '10px',
                marginRight: '10px',
                height: 45
            }}
        >
            <input
                type="text"
                value={searchValue}
                onChange={(e) => onSearchChange(e.target.value)}
                placeholder="Search by title or trainer..."
                className={'search-input'}
                style={{
                    marginRight: 10
                }}
            />
            <button
                type="button"
                className={'calendar-button'}
                style={{
                    padding: '5px 10px',
                    marginLeft: 10,
                    width: 50
                }}
            >
                <i className="fas fa-search"></i>
            </button>
        </div>
    );
};

const CustomEvent = ({ event, handleSelect, setContextMenu }) => {

    // console.log(event);

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = (e) => {
        setTooltipVisible(true);
        setTooltipPosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    };

    const handleClick = () => {
        setTooltipVisible(false);
        handleSelect(event.classId); // Trigger navigation
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        setTooltipVisible(false); // Hide tooltip
        const tooltip = document.getElementById(`event-tooltip-${event.id}`);
        if (tooltip) {
            tooltip.remove();
        }
        setContextMenu({
            visible: true,
            x: e.clientX,
            y: e.clientY,
            eventData: event,
        });
    };

    return (
        <div
            style={{ position: 'relative', cursor: 'pointer' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            onContextMenu={handleContextMenu}
        >
            {event.title}
            {tooltipVisible && (
                <div
                    style={{
                        position: 'fixed',
                        left: tooltipPosition.x,
                        top: tooltipPosition.y - 150,
                        padding: '8px',
                        backgroundColor: 'rgba(0, 0, 0, 1)',
                        color: 'white',
                        borderRadius: '4px',
                        pointerEvents: 'none',
                        zIndex: '9999',
                    }}
                >
                    <h6><strong>{event.title}</strong></h6>
                    <br />
                    <h6>Trainer: {event.ownerName}</h6>
                    <br />
                    <h6>Start: {moment(event.start).format('MMMM Do, h:mm A')}</h6>
                    <h6>End: {moment(event.end).format('MMMM Do, h:mm A')}</h6>
                </div>
            )}
        </div>
    );
};




const CustomToolbar = (props) => {
    const { label, onNavigate, setCurrentMonth  } = props; // Extract the label (e.g., "January 2025")
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const arrowButtonStyles = {
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: '15px',
        display: 'inline-block',
        margin: '0 5px',
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setAnchorEl(false);

        const newDate = moment(date).toDate(); // Convert Moment.js object to JS Date
        onNavigate('DATE', newDate); // Navigate to the selected date
        setCurrentMonth(moment(date).format('YYYY-MM')); // Update the parent component's state
    };

    // Handle opening the date picker
    const handleLabelClick = () => {
        setAnchorEl(!anchorEl);
    };

    const renderCustomHeader = ({ date, decreaseYear, increaseYear }) => {
        const currentYear = date.getFullYear();

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: '16px',
                    padding: '5px 10px',
                    backgroundColor: '#ffffff',
                    borderBottom: '1px solid #ccc',
                }}
            >
                <button
                    type="button"
                    onClick={decreaseYear}
                    style={{ cursor: 'pointer', fontSize: '14px', padding: '5px', marginRight: 10 }}
                >
                    {'< ' + (currentYear - 1)}
                </button>
                <span style={{ fontWeight: 'bold', margin: 10 }}>{currentYear}</span>
                <button
                    type="button"
                    onClick={increaseYear}
                    style={{ cursor: 'pointer', fontSize: '14px', padding: '5px', marginLeft: 10 }}
                >
                    {(currentYear + 1) + ' >'}
                </button>
            </div>
        );
    };


    return (
        <div className="rbc-toolbar" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgb(239,239,239)', justifyContent: 'space-between' }}>
            {/* Navigation Buttons */}
            <span className="rbc-btn-group">
                <button
                    type="button"
                    className={'calendar-button'}
                    style={arrowButtonStyles}
                    onClick={() => props.onNavigate('PREV')}
                >
                    &#8249;
                </button>
                <button
                    type="button"
                    className={'calendar-button'}
                    onClick={() => props.onNavigate('TODAY')}
                >
                    Today
                </button>
                <button
                    type="button"
                    className={'calendar-button'}
                    style={arrowButtonStyles}
                    onClick={() => props.onNavigate('NEXT')}
                >
                    &#8250;
                </button>
            </span>

            {/* Month/Year Label */}
            <span
                className="rbc-toolbar-label"
                style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: 'black'
                }}
                onClick={handleLabelClick}
            >
                {label}
            </span>

            {/* Month/Year Picker */}
            {anchorEl && (
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '15%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                        backgroundColor: 'white',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        borderRadius: '8px',
                        padding: '10px',
                        borderStyle: 'none',
                        color: 'black'
                    }}
                >
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        inline
                        renderCustomHeader={renderCustomHeader}
                    />
                </div>
            )}
            {/* View Buttons */}
            <span className="rbc-btn-group">
                <button
                    type="button"
                    className={'calendar-button'}
                    onClick={() => props.onView('month')}
                >
                    Month
                </button>
                <button
                    type="button"
                    className={'calendar-button'}
                    onClick={() => props.onView('week')}
                >
                    Week
                </button>
                <button
                    type="button"
                    className={'calendar-button'}
                    onClick={() => props.onView('day')}
                >
                    Day
                </button>
            </span>
        </div>
    );
};



const CustomCalendar = () => {
    const [events, setEvents] = useState([]);
    const fetchApi = useFetchApi();
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [showLongEvents, setShowLongEvents] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(moment().format('YYYY-MM')); // Track the displayed month
    const navigate = useNavigate();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        x: 0,
        y: 0,
        eventData: null,
    });

    // Filter events based on search and checkbox
    useEffect(() => {
        let filtered = events;

        // Filter by search value
        if (searchValue) {
            filtered = filtered.filter(
                (event) =>
                    event.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                    event.ownerName.toLowerCase().includes(searchValue.toLowerCase())
            );
        }

        // Filter out long events if checkbox is unchecked
        if (!showLongEvents) {
            filtered = filtered.filter((event) => {
                const eventDuration = (new Date(event.end) - new Date(event.start)) / (1000 * 60 * 60 * 24); // Duration in days
                return eventDuration <= 30; // Keep events shorter than or equal to 30 days
            });
        }

        setFilteredEvents(filtered);
    }, [searchValue, showLongEvents, events]);

    useEffect(() => {
        fetchEvents(currentMonth);
    }, [currentMonth]);

    const updateCalendar = (data) => {

        const formattedEvents = data.appointments.map((appointment) => ({
            id: appointment.id,
            classId: appointment.classId,
            title: appointment.title,
            start: new Date(appointment.startDate),
            end: new Date(appointment.endDate || appointment.startDate),
            ownerName: appointment.ownerName,
            ownerColor: appointment.ownerColor
        }));

        setEvents(formattedEvents);

    }
    // Fetch events from the backend
    const fetchEvents = async (month) => {

        // try {
        //     const response = await fetch('https://10.1.10.49:5001/calendar/events', {
        //         method: 'POST',
        //         credentials: 'include',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify({ month }),
        //     });
        //
        //     if (!response.ok) throw new Error('Failed to fetch events');
        //
        //     const data = await response.json();
        //
        // } catch (error) {
        //     console.error('Error fetching events:', error);
        // }

        const bodyData = {month}

        fetchApi("calendar/events", bodyData)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    updateCalendar(response.responseData)
                    // setMsgCode(0);
                } else {
                    // setMsg(response.msg);
                    // setMsgCode(1);
                    console.log('Error fetching calendar events:');
                }
            })
            .catch((error) => {
                // setMsg(
                //     "An unexpected error occurred. Contact your administrator" + error
                // );
                // setMsgCode(4);
            });

    };

    const handleNavigate = (action, newDate = null) => {
        let newMonth;

        if (action === 'TODAY') {
            newMonth = moment().format('YYYY-MM'); // Set to the current month
        } else if (action === 'DATE' && newDate) {
            newMonth = moment(newDate).format('YYYY-MM'); // Use the new date from the picker
        } else {
            newMonth = moment(currentMonth, 'YYYY-MM')
                .add(action === 'NEXT' ? 1 : -1, 'month')
                .format('YYYY-MM');
        }

        setCurrentMonth(newMonth);
    };

    const handleSelect = (classId) => {
        navigate('./class-edit', { state: { classId } });
    };

    return (

        <div
            style={{
                position: 'relative', // Establish stacking context for Bluey
                minHeight: '100vh', // Ensure it spans the full viewport height
            }}
        >
            <Bluey/>

            <div style={{ position: 'relative', zIndex: 1 }}>

                <div onContextMenu={(e) => e.preventDefault()}>

                    {contextMenu.visible && (
                        <div
                            style={{
                                position: 'absolute',
                                top: contextMenu.y,
                                left: contextMenu.x,
                                backgroundColor: 'white',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                borderRadius: '4px',
                                zIndex: 9999,
                                padding: '8px',
                            }}
                            onMouseLeave={() => setContextMenu({
                                visible: false,
                                x: 0,
                                y: 0,
                                eventData: null
                            })} // Close menu on mouse leave
                        >
                            <div
                                style={{
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    transition: 'background-color 0.2s ease',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
                                onClick={() => {
                                    navigate('./class-edit', {state: {classId: contextMenu.eventData.classId}});
                                    setContextMenu({visible: false, x: 0, y: 0, eventData: null}); // Close menu after action
                                }}
                            >
                                Edit Class
                            </div>
                            <div
                                style={{
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    transition: 'background-color 0.2s ease',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
                                onClick={() => {
                                    // navigate('/classroom', {state: {classId: contextMenu.eventData.classId}});
                                    const classId = contextMenu.eventData?.classId || 'Unknown';
                                    if (classId === 'Unknown') {
                                        alert('Class ID not found for this event.');
                                    } else {
                                        navigate('/classroom', {state: {classId}});
                                    }
                                    setContextMenu({visible: false, x: 0, y: 0, eventData: null}); // Close menu after action
                                }}
                            >
                                Go to Classroom
                            </div>
                            <div
                                style={{
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    transition: 'background-color 0.2s ease',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
                                onClick={() => {
                                    alert(`Event Title: ${contextMenu.eventData.title}`);
                                    setContextMenu({visible: false, x: 0, y: 0, eventData: null});
                                }}
                            >
                                View Details
                            </div>
                        </div>
                    )}

                    {/* Calendar Wrapper */}
                    <StyledCalendarWrapper>
                        {/* Custom Toolbar */}
                        <StyledToolbar>
                            <Typography variant="h4">CALENDAR</Typography>
                            <CustomSearchBar
                                searchValue={searchValue}
                                onSearchChange={setSearchValue}
                            />

                            <button className={'btn-primary'} onClick={() => navigate('/calendar/class-edit')}>
                                NEW CLASS
                            </button>

                        </StyledToolbar>
                        <CheckboxLabel>
                            <input
                                type="checkbox"
                                // className="radio-as-checkbox"
                                checked={showLongEvents}
                                onChange={(e) => setShowLongEvents(e.target.checked)}
                                style={{margin: '10px', cursor: 'pointer'}}
                            />
                            <strong>Show Long Events</strong>
                        </CheckboxLabel>

                        <Calendar
                            localizer={localizer}
                            events={filteredEvents}
                            startAccessor="start"
                            endAccessor="end"
                            date={moment(currentMonth, 'YYYY-MM').toDate()} // Pass the currentMonth as the controlled date
                            style={calendarStyles.calendar}
                            selectable
                            views={['month', 'week', 'day']}
                            popup
                            tooltipAccessor={() => null}
                            onSelectEvent={(event) => handleSelect(event.classId)}
                            // onSelectSlot={(slotInfo) => alert(`Selected slot: ${slotInfo.start}`)}
                            eventPropGetter={(event, start) => {
                                const numberOfEvents = filteredEvents.filter(
                                    (e) =>
                                        new Date(e.start).toDateString() === new Date(start).toDateString()
                                ).length;
                                return {
                                    style: {
                                        ...calendarStyles.event,
                                        height: `${Math.max(numberOfEvents * 20, 100)}px`, // Minimum 100px, grows with event count
                                        backgroundColor: event.ownerColor ? event.ownerColor : '#624b88', // Default to gray if no owner found
                                    },
                                    // title: `Title: ${event.title}\nOwner: ${event.ownerName}\nStart: ${moment(event.start).format('MMMM Do, h:mm A')}\nEnd: ${moment(event.end).format('MMMM Do, h:mm A')}`,
                                };
                            }}
                            onNavigate={(newDate) => {
                                const newMonth = moment(newDate).format('YYYY-MM');
                                setCurrentMonth(newMonth);
                            }}
                            components={{

                                event: (props) => (
                                    <CustomEvent
                                        {...props}
                                        setTooltipVisible={() => {
                                        }}
                                        handleSelect={(classId) => navigate('./class-edit', {state: {classId}})}
                                        setContextMenu={setContextMenu}
                                    />
                                ),
                                toolbar: (props) => (
                                    <CustomToolbar
                                        {...props}
                                        setCurrentMonth={setCurrentMonth}
                                        onNavigate={(action, newDate) => {
                                            props.onNavigate(action);
                                            handleNavigate(action, newDate);
                                        }}
                                    />
                                ),
                            }}
                        />

                    </StyledCalendarWrapper>
                </div>

            </div>

        </div>
    );
};

export default CustomCalendar;

// =============================== To Do =================================== //

// Better Mobile View
// Styling cleanup

// =============================== Bugs =================================== //

// Context menu (on right click) position varies randomly
