import React, {useContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import ClassroomComponent from "Components/ClassroomComponent";
import Bluey from "Components/Bluey";


export default function Classroom() {

    const location = useLocation(); // Get location object
    const queryParams = new URLSearchParams(location.search);
    // const classId = location.state?.classId; // Extract classId from state
    // const classId = queryParams.get('classId');
    const [classId, setClassId] = useState(location.state?.classId || queryParams.get('classId' || null));


    useEffect(() => {
        // Determine classId from state or query params
        const id = location.state?.classId || queryParams.get('classId');
        setClassId(id); // Set classId once determined
        console.log('ClassId:', id);
    }, [location.state, queryParams]);


    return (

        <div
            style={{
                position: 'relative',
                minHeight: '100vh',
                background: 'transparent',
                overflow: 'hidden', // Ensure no content cuts off Bluey
            }}
        >
            <Bluey/>

            <div style={{position: 'relative', zIndex: 1}}>
                <div className="container" style={{width: '50%'}}></div>
                <ClassroomComponent classId={classId} style={{}}/>
            </div>
        </div>

    );
}




