import React from 'react';
import SpacerDiv from './SpacerDiv';

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <div 
    className='footer-container' 
    style={{ color: '#393939', fontSize: '18px', fontWeight: '500'}}
    >
    <SpacerDiv rem={2}/>
     Training Portal © - AVETMISS 8 Compliant
     <SpacerDiv rem={1}/>
    </div>
  );
};

export default Footer;