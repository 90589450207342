import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MicroMarking {
  [key: string]: string;
}

interface QuestionData {
  answers: MicroMarking;
  feedback: string;
  outcome: string;
}

interface AssessmentState {
  [qNum: string]: QuestionData;
}

const initialState: AssessmentState = {};

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    saveAnswer: (
      state,
      action: PayloadAction<{
        qNum: string;
        key: string;
        value: string;
      }>
    ) => {
      const { qNum, key, value } = action.payload;
      if (!state[qNum]) {
        state[qNum] = { answers: {}, feedback: '', outcome: '' };
      }
      state[qNum] = {
        ...state[qNum],
        answers: {
          ...state[qNum].answers,
          [key]: value,
        },
      };
    },
    saveFeedback: (
      state,
      action: PayloadAction<{
        qNum: string;
        value: string;
      }>
    ) => {
      console.log('saveFeedback', action.payload);
      const { qNum, value } = action.payload;
      if (!state[qNum]) {
        state[qNum] = { answers: {}, feedback: '', outcome: '' };
      }
      state[qNum] = {
        ...state[qNum],
        feedback: value,
      };
    },
    saveOutcome: (
      state,
      action: PayloadAction<{
        qNum: string;
        value: string;
      }>
    ) => {
      console.log('save Outcome', action.payload);
      const { qNum, value } = action.payload;
      if (!state[qNum]) {
        state[qNum] = { answers: {}, feedback: '', outcome: '' };
      }
      state[qNum] = {
        ...state[qNum],
        outcome: value,
      };
    },
    clearAssessment: () => {
      // Completely reset state
      return {};
    },
  },
});

export const { saveAnswer, saveFeedback, saveOutcome, clearAssessment } =
  assessmentSlice.actions;

export default assessmentSlice.reducer;
