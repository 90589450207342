import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import CustomAlert from '../CustomAlert';
import { useUserField, useUserData, useUserDeets, useAccessLevel } from 'CustomHooks';

interface ProtectedRouteProps {
  requiredLevel: number;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredLevel }) => {
  const [showAlert, setShowAlert] = React.useState(false);
  const navigate = useNavigate();
  const { clearUserData } = useUserData();
  const isRestoring = useUserField('isRestoring');
  const userData = useUserDeets();

  // Use the access level hook to get the user's access level from cookies, localStorage, or the Redux store
  const { accessLevel, msg, msgCode } = useAccessLevel(userData.accessLevel);


  const handleClose = () => {
    setShowAlert(false);
    navigate('/login');
  };

  useEffect(() => {
    if (isRestoring) return; 

    if (accessLevel === null) {
      console.log('You do not have a valid access level. Logging out...');
      clearUserData();
      navigate('/login'); 
    } 
    else if (accessLevel < requiredLevel) {
      setShowAlert(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessLevel, requiredLevel, navigate, isRestoring]);

  if (accessLevel === null || accessLevel < requiredLevel) {
    return (
      <>
        {showAlert && (
          <CustomAlert
            msg="You do not have the required access level to view this page."
            msgCode={3}
            onClose={handleClose}
            timeout={2000}
          />
        )}
      </>
    );
  }

  // Render the protected content if the user has the required access level
  return <Outlet />;
};

export default ProtectedRoute;
