import backgroundImage from "../Images/TPLogoHighRes.png";
// const navigate = useNavigate();
const Bluey = () => {

//     Functions here

    return (


        <div
            style={{
                position: 'absolute',
                top: 100,
                right: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${backgroundImage})`,
                opacity: 0.3,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center',
                backgroundSize: 'auto 100%',
                zIndex: 0, // Keep it at the base level within its container
                pointerEvents: 'none', // Ensure it doesn't block interactions
            }}
            ></div>
        // </div>
    );
};

export default Bluey;

// =============================== To Do =================================== //



// =============================== Bugs =================================== //
